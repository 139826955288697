import React, { useState } from 'react';

import { Button, Dropdown, MenuProps, Tooltip } from 'antd';

import { ICommandProps } from '@controls/toolbar/toolbar';

import './button.css';

export interface IControlProps {
    item: ICommandProps;
}

const DropdownControl = (props: IControlProps) => {
    const { item } = props;

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const items: MenuProps['items'] = [];
    if (item.children) {
        item.children.map((e: any) => items.push(e));
    }

    return (
        <Tooltip key={item.key} placement='top' title={item.label}>
            <Dropdown
                key={item.key + 'DropdownControl'}
                className='toolbar-dropdown-control'
                disabled={item.disabled}
                menu={{ items }}
                trigger={['click']}
                onOpenChange={() => setIsExpanded(!isExpanded)}
            >
                <Button
                    key={item.key + 'DropdownControlInnerButton'}
                    className='toolbar-dropdown-control-inner-button'
                    disabled={item.disabled}
                    type={'text'}
                    size='large'
                    icon={item.icon}
                    style={{ borderRadius: 0 }}
                >
                    {item.showLabel && item.label}
                </Button>
            </Dropdown>
        </Tooltip>
    );
};

export default DropdownControl;
