import React from 'react';

import { Flex, Space, Button, Tooltip, Divider } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

interface IFilter {
    display: boolean;
    items: Array<any>;
    onClear: () => void;
}

const Filter = (props: IFilter) => {
    const { display, items, onClear } = props;

    return (
        <div style={{ display: display ? 'block' : 'none' }}>
            <Divider style={{ marginTop: 0, marginBottom: 10 }} />
            <Flex justify='space-between' style={{ marginBottom: 15 }}>
                {items && (
                    <Space wrap>
                        {items.map((item) => {
                            return item;
                        })}
                    </Space>
                )}
                <Space direction='vertical'>
                    <Tooltip placement='left' title='Очистить фильтр'>
                        <Button icon={<ClearOutlined />} onClick={onClear} size='middle'></Button>
                    </Tooltip>
                </Space>
            </Flex>
        </div>
    );
};

export default Filter;
