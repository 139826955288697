export enum BillStatus {
    New = 1,
    ToVerify = 2,
    Completed = 3,
    Paid = 4,
}

export const enumLabel = (value?: BillStatus) => {
    switch (value) {
        case BillStatus.New:
            return 'Новый';
        case BillStatus.ToVerify:
            return 'На проверке';
        case BillStatus.Completed:
            return 'Проведен';
        case BillStatus.Paid:
            return 'Оплачен';
        default:
            return '';
    }
};
