import React from 'react';

import { LockOutlined } from '@ant-design/icons';

import { Permission, enumLabel } from '@enums/permission';
import { NotificationInstance } from 'antd/es/notification/interface';
import SecurityRestrictionControl from '../controls/security-restriction/security-restriction-control';

const duration: number = 15;

export const exception = (api: NotificationInstance, message: string, ex: any, logout: any) => {
    api.error({
        message: message,
        description: ex && ex.userMessage && ex.userMessage.replace(/\r/g, ', '),
        placement: 'bottomRight',
        duration: duration,
    });

    if (ex.statusCode === 401 && logout) logout();
};

export const error = (api: NotificationInstance, message: string, description: string = '') => {
    api.error({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const success = (api: NotificationInstance, message: string, description: string = '') => {
    api.success({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const warning = (api: NotificationInstance, message: string, description: any = undefined) => {
    api.warning({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const info = (api: NotificationInstance, message: string, description: any = undefined) => {
    api.info({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const securityRestriction = (api: NotificationInstance, permissions: Array<Permission> = []) => {
    let permissionLabel = permissions.map((p) => {
        return enumLabel(p);
    });

    api.open({
        message: 'Ограничение безопасности',
        placement: 'bottomRight',
        description: <SecurityRestrictionControl message={'Извините, у вас нет прав доступа'} permissionLabel={permissionLabel} />,
        duration: duration,
        icon: <LockOutlined />,
        style: { width: 600 },
    });
};
