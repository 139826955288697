import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input, InputNumber, Form, Space, notification, Divider, Table, Row, Col, Tag, Tooltip, Tabs, Flex } from 'antd';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { MaskedInput } from 'antd-mask-input';

import FormHeader from '@controls/form-header/form-header';

import { toFinanceString } from '@extensions/utils';

import { exception } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { IConsignee } from '@entities/consignee';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserDebt } from '@entities/user-debt';
import { IUserDebtFilter } from '@entities/user-debt-filter';
import { IUserDiscount } from '@entities/user-discount';
import { IUserDiscountFilter } from '@entities/user-discount-filter';
import { ICompany } from '@entities/company';
import { ICompanyFilter } from '@entities/company-filter';
import { IUserAccount } from '@entities/user-account';

import { UserType } from '@enums/user-type';
import { Currency, enumSign as currencySign } from '@src/core/enums/currency';
import { enumLabel as discountTypeLabel } from '@enums/discount-type';
import { WarehouseType, enumLabel as warehouseTypeLabel } from '@enums/warehouse-type';

import { MobileIcon, MobileNoneIcon } from '@icons/index';
import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

interface IAccountParams {
    userId: string | undefined;
    newPassword: string | undefined;
}

const UserAccount = () => {
    const { TextArea } = Input;

    const navigate = useNavigate();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IUser>();
    const [loading, setLoading] = useState<boolean>(false);
    const [allowSetPassword, setAllowSetPassword] = useState(false);

    const [userDebts, setUserDebts] = useState<Array<IUserDebt>>([]);
    const [loadingUserDebts, setLoadingUserDebts] = useState<boolean>(false);
    const [userDebtsRefreshRequired, setUserDebtsRefreshRequired] = useState<boolean>();

    const [discounts, setDiscounts] = useState<Array<IUserDiscount>>([]);
    const [discountsRefreshRequired, setDiscountsRefreshRequired] = useState<boolean>();
    const [loadingDiscounts, setLoadingDiscounts] = useState<boolean>(false);

    const [companies, setCompanies] = useState<Array<ICompany>>([]);
    const [companiesRefreshRequired, setCompaniesRefreshRequired] = useState<boolean>();
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);

    const [employees, setEmployees] = useState<Array<IUser>>([]);
    const [employeesRefreshRequired, setEmployeesRefreshRequired] = useState<boolean>();
    const [loadingEmployees, setLoadingEmployees] = useState<boolean>(false);

    const [accounts, setAccounts] = useState<Array<IUserAccount>>([]);
    const [accountsRefreshRequired, setAccountsRefreshRequired] = useState<boolean>();

    useEffect(() => {
        setLoading(true);

        serverFetch(`users/account`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения учетной записи', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (entity) {
            setUserDebtsRefreshRequired(true);
            setDiscountsRefreshRequired(true);
            setCompaniesRefreshRequired(true);
            setEmployeesRefreshRequired(true);
            setAccountsRefreshRequired(true);
        }
    }, [entity]);

    useEffect(() => {
        if (!accountsRefreshRequired) return;

        setAccounts([]);
        setAccountsRefreshRequired(false);

        serverFetch(`users/${userSession.userId}/accounts`, { method: 'GET' })
            .then((data) => {
                setAccounts(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
            });
    }, [accountsRefreshRequired]);

    useEffect(() => {
        if (!userDebtsRefreshRequired) return;

        setUserDebts([]);

        setUserDebtsRefreshRequired(false);

        setLoadingUserDebts(true);

        var userDebtFilter: IUserDebtFilter = {
            userId: entity?.id,
        };

        serverFetch(`userdebts`, { method: 'GET', queryParams: userDebtFilter })
            .then((data) => {
                setUserDebts(data);
                setLoadingUserDebts(false);
            })
            .catch((ex) => {
                setLoadingUserDebts(false);
                exception(api, 'Ошибка получения долгов перед клиентом', ex, () => d(userLoaded(undefined)));
            });
    }, [userDebtsRefreshRequired]);

    useEffect(() => {
        if (!discountsRefreshRequired) return;

        setDiscounts([]);

        setDiscountsRefreshRequired(false);

        setLoadingDiscounts(true);

        var discountFilter: IUserDiscountFilter = {
            userId: entity?.id,
        };

        serverFetch(`userdiscounts`, { method: 'GET', queryParams: discountFilter })
            .then((data) => {
                setDiscounts(data);
                setLoadingDiscounts(false);
            })
            .catch((ex) => {
                setLoadingDiscounts(false);
                exception(api, 'Ошибка получения скидок', ex, () => d(userLoaded(undefined)));
            });
    }, [discountsRefreshRequired]);

    useEffect(() => {
        if (!companiesRefreshRequired) return;

        setCompanies([]);

        setCompaniesRefreshRequired(false);

        setLoadingCompanies(true);

        var companyFilter: ICompanyFilter = {
            userId: entity?.id,
        };

        serverFetch(`companies`, { method: 'GET', queryParams: companyFilter })
            .then((data) => {
                setCompanies(data);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                setLoadingCompanies(false);
                exception(api, 'Ошибка получения контрагентов', ex, () => d(userLoaded(undefined)));
            });
    }, [companiesRefreshRequired]);

    useEffect(() => {
        if (!employeesRefreshRequired) return;

        setCompanies([]);

        setEmployeesRefreshRequired(false);

        setLoadingEmployees(true);

        serverFetch(`users/${entity?.id}/employees`, { method: 'GET' })
            .then((data) => {
                setEmployees(data);
                setLoadingEmployees(false);
            })
            .catch((ex) => {
                setLoadingEmployees(false);
                exception(api, 'Ошибка получения сотрудников', ex, () => d(userLoaded(undefined)));
            });
    }, [employeesRefreshRequired]);

    const onFinishSave = () => {
        if (!entity) return;

        setLoading(true);

        var account: IAccountParams = { userId: entity.id, newPassword: entity.newPassword };
        serverFetch(`users/account`, { method: 'PUT', bodyData: account })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения учетной записи', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderAccounts = () => {
        return (
            accounts.length > 0 && (
                <div>
                    <Divider orientation='left'>Cчета</Divider>

                    <Flex justify='flex-start' align='center' gap='large'>
                        {accounts.map((a) => {
                            return (
                                <Flex key={a.currency} justify='center'>
                                    {a.currency == Currency.Rub ? (
                                        <RubleFilledIcon />
                                    ) : a.currency == Currency.Usd ? (
                                        <UsdFilledIcon />
                                    ) : (
                                        <EuroFilledIcon />
                                    )}
                                    <div style={{ fontSize: 18, fontWeight: 600 }}>{toFinanceString(a.amount || 0, 2)}</div>
                                </Flex>
                            );
                        })}
                    </Flex>
                </div>
            )
        );
    };

    const renderUserDebts = () => {
        const columns: ColumnsType<IUserDebt> = [
            {
                title: 'Название',
                width: 250,
                render: (_: any, record) => {
                    return record.debtName;
                },
            },
            {
                title: 'Сумма',
                width: 180,
                render: (_: any, record) => {
                    return (
                        <>
                            {record.amount} {currencySign(record.currency)}
                        </>
                    );
                },
            },
            {},
        ];

        return (
            <div style={{ marginBottom: 20 }}>
                <Divider style={{ background: 'var(--main-red)', marginTop: 0 }} orientation='left'>
                    Долги перед клиентом
                </Divider>

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={userDebts}
                    loading={{
                        spinning: loadingUserDebts,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderDiscounts = () => {
        const columns: ColumnsType<IUserDiscount> = [
            {
                title: 'Тип',
                width: 250,
                render: (_: any, record) => {
                    return discountTypeLabel(record.type);
                },
            },
            {
                title: 'Скидка',
                width: 180,
                render: (_: any, record) => {
                    return <>{record.value} $</>;
                },
            },
            {
                title: 'В счет долга',
                width: 180,
                render: (_: any, record) => {
                    return record.debtName && <Tag color='var(--main-red)'>{record.debtName}</Tag>;
                },
            },
            {},
        ];

        return (
            <div style={{ marginBottom: 20 }}>
                <Divider style={{ marginTop: 0 }} orientation='left'>
                    Скидки
                </Divider>
                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={discounts}
                    loading={{
                        spinning: loadingDiscounts,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderCompanies = () => {
        const columns: ColumnsType<ICompany> = [
            {
                title: 'Название',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'ИНН',
                width: 180,
                dataIndex: 'inn',
            },
            {
                title: 'Юр. адрес',
                width: 300,
                dataIndex: 'legalAddress',
            },
            {
                title: 'Основной',
                width: 90,
                align: 'center',
                render: (_: any, record) => {
                    return record.isMain ? <CheckOutlined /> : '';
                },
            },
            {},
        ];

        return (
            <div style={{ marginBottom: 20 }}>
                <Divider style={{ marginTop: 0 }} orientation='left'>
                    Контрагенты
                </Divider>

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={companies}
                    loading={{
                        spinning: loadingCompanies,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderEmployees = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'Логин',
                dataIndex: 'login',
                width: 200,
            },
            {
                title: 'Разрешения',
                width: 150,
                align: 'left',
                render: (_, record) => {
                    return (
                        <ul>
                            {record.userSettings.showBills && <li>Счета</li>}
                            {record.userSettings.showNotifications && <li>Уведомления</li>}
                        </ul>
                    );
                },
            },
            {
                title: 'Активно',
                dataIndex: 'isActive',
                width: 80,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return record.isActive ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                width: 35,
                align: 'center',
                render: (_, record) => {
                    return record.firebaseToken ? (
                        <Tooltip title='Приложение "My Freshline" используется'>
                            <MobileIcon style={{ color: 'green', fontSize: 20 }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Приложение "My Freshline" не используется.'>
                            <MobileNoneIcon style={{ color: 'red', fontSize: 20 }} />
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 300,
            },
            {},
        ];

        return (
            <div style={{ marginBottom: 20 }}>
                <Divider style={{ marginTop: 0 }} orientation='left'>
                    Сотрудники
                </Divider>
                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={employees}
                    loading={{
                        spinning: loadingEmployees,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 20px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IConsignee> = [
            {
                title: 'ID',
                align: 'center',
                dataIndex: 'code',
                width: 70,
            },
            {
                title: 'Маркировка',
                width: 300,
                dataIndex: 'allMarkings',
            },
            {
                title: 'Город доставки',
                width: 150,
                dataIndex: 'cityName',
            },
            {
                title: 'Склад',
                align: 'center',
                width: 70,
                render: (_, record) => {
                    return (
                        <Tooltip title={`${warehouseTypeLabel(record.warehouseType)} склад`}>
                            <div className={record.warehouseType === WarehouseType.Main ? 'mainWarehouseTag' : 'transitWarehouseTag'}>
                                {record.warehouseCode}
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                title: 'ФИО',
                width: 250,
                dataIndex: 'fullName',
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                title: 'Адрес',
                width: 350,
                dataIndex: 'address',
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entity?.consignees}
                pagination={false}
                scroll={{ y: `calc(100vh - 330px)` }}
            />
        );
    };

    const renderUserForm = () => {
        return (
            entity && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} style={{ marginBottom: 40 }} onFinish={onFinishSave}>
                    <Form.Item initialValue={entity?.login} label='Логин' name='code'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item label='Пароль' rules={[{ required: true, message: 'Укажите пароль' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input.Password
                                value={entity.newPassword}
                                disabled={!allowSetPassword}
                                placeholder={!allowSetPassword ? '*******' : ''}
                                onChange={(data) => {
                                    setEntity({ ...entity, newPassword: data.target.value });
                                }}
                            />
                            <Button
                                disabled={entity.isDemo}
                                type='primary'
                                onClick={() => {
                                    setAllowSetPassword(true);
                                }}
                            >
                                Сменить пароль
                            </Button>
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item initialValue={entity?.fullName} label='ФИО' name='fullName'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item label='Телефон'>
                        <MaskedInput value={entity?.phone} disabled={true} size='middle' mask={'+7 (000) 000-00-00'} />
                    </Form.Item>
                    <Form.Item initialValue={entity?.email} label='Email' name='email'>
                        <Input disabled={true} />
                    </Form.Item>

                    {entity.newPassword && (
                        <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                            <Space size={'small'} style={{ float: 'right', marginTop: 0 }}>
                                <Button
                                    type='link'
                                    onClick={() => {
                                        setEntity({ ...entity, newPassword: undefined });
                                        setAllowSetPassword(false);
                                    }}
                                >
                                    Отменить
                                </Button>
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            </Space>
                        </Form.Item>
                    )}
                </Form>
            )
        );
    };

    const renderConsignees = () => {
        if (userSession.type == UserType.Supplier) {
            return (
                <>
                    <Divider orientation='left'>Грузополучатели</Divider>
                    {renderTable()}
                </>
            );
        }

        return (
            entity &&
            entity.consignees?.map((c) => {
                return (
                    <>
                        <Divider orientation='left'>Грузополучатель</Divider>

                        <Form
                            key={c.id}
                            colon={false}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ marginBottom: 40 }}
                            onFinish={onFinishSave}
                        >
                            <Form.Item initialValue={c.code} label='ID' name='code'>
                                <InputNumber disabled={true} />
                            </Form.Item>
                            <Form.Item initialValue={c.allMarkings} label='Маркировка' name='allMarkings'>
                                <TextArea disabled={true} rows={3} />
                            </Form.Item>
                            <Form.Item initialValue={c.cityName} label='Город доставки' name='city'>
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item initialValue={c.fullName} required label='ФИО' name='fullName'>
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item label='Телефон'>
                                <MaskedInput value={c.phone} disabled={true} size='middle' mask={'+7 (000) 000-00-00'} />
                            </Form.Item>
                            <Form.Item initialValue={c.cityName} label='Город' name='cityName'>
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item initialValue={c.address} label='Адрес' name='address'>
                                <Input disabled={true} />
                            </Form.Item>
                        </Form>
                    </>
                );
            })
        );
    };

    let tabItems = [
        {
            label: `Основное`,
            key: 'main',
            children: renderUserForm(),
        },
        {
            label: `Грузополучатели`,
            key: 'consignees',
            children: renderConsignees(),
        },
        /* {
            label: `Финансы`,
            key: 'finance',
            children: (
                <Row>
                    <Col span={12}>
                        {accounts?.length > 0 && renderAccounts()}
                        {discounts?.length > 0 && renderDiscounts()}
                    </Col>
                    <Col offset={1} span={11}>
                        {userDebts?.length > 0 && renderUserDebts()}
                    </Col>
                </Row>
            ),
        }, */
        {
            label: `Контрагенты`,
            key: 'companies',
            children: <Row>{companies.length > 0 && renderCompanies()}</Row>,
        },
    ];

    if (userSession.type == UserType.Supplier) {
        tabItems.push({
            label: `Сотрудники`,
            key: 'employees',
            children: <Row> {employees?.length > 0 && renderEmployees()}</Row>,
        });
    }

    return (
        <>
            <FormHeader title={`Личный кабинет`} />
            {!loading && entity && <Tabs size='large' items={tabItems} />}

            {contextHolder}
        </>
    );
};

export default UserAccount;
