import React from 'react';

import { Space, Button, Tooltip, Flex } from 'antd';

import './toolbar.css';
import { ButtonType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import DropdownControl from '@controls/button/dropdown-control';

interface IToolbar {
    commands: Array<ICommandProps>;
    farControl?: any;
    farCommands?: Array<any>;
    style?: any;
}

export interface ICommandProps {
    key: string;
    label: string;
    disabled?: boolean;
    type?: ButtonType;
    icon?: React.ReactNode;
    onClick?: () => void;
    size?: SizeType;
    showLabel?: boolean;
    style?: any;
    children?: any[];
    hidden?: boolean;
}

const Toolbar = (props: IToolbar) => {
    const { commands, farControl, farCommands, style } = props;
    return (
        <div className='commands-container' style={style}>
            <Space>
                {commands.map((item: ICommandProps) => {
                    return (
                        !item.hidden && (
                            <Tooltip key={item.key} placement='top' title={item.label}>
                                {item.children ? (
                                    <DropdownControl item={item} />
                                ) : (
                                    <Button
                                        disabled={item.disabled}
                                        type={item.type}
                                        icon={item.icon}
                                        style={item.style}
                                        onClick={item.onClick}
                                        size={item.size || 'middle'}
                                    >
                                        {item.showLabel && item.label}
                                    </Button>
                                )}
                            </Tooltip>
                        )
                    );
                })}
            </Space>

            <Space>
                <Flex gap='large'>
                    {farControl}
                    {farCommands?.map((item: any) => {
                        return (
                            <Tooltip key={item.key} placement='left' title={item.label}>
                                <Button
                                    disabled={item.disabled}
                                    type={item.type}
                                    icon={item.icon}
                                    style={item.style}
                                    onClick={item.onClick}
                                    size='middle'
                                ></Button>
                            </Tooltip>
                        );
                    })}
                </Flex>
            </Space>
        </div>
    );
};

export default Toolbar;
